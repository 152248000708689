export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/admin/curated-list-block": [4],
		"/admin/editorials": [5],
		"/admin/editorials/[id]": [6],
		"/admin/featured-collections": [7],
		"/admin/featured-collections/[offerable_type]/[id]": [8,[2]],
		"/admin/listing": [9],
		"/admin/membership-settings": [10],
		"/admin/mobile-app-config": [11],
		"/admin/partners": [12],
		"/admin/partners/[id]": [13],
		"/admin/snackable-questions": [14],
		"/admin/staff": [15],
		"/admin/volunteers": [16],
		"/admin/volunteers/[id]": [17],
		"/auth-verification/[token]": [18],
		"/form_submissions": [19],
		"/login": [20],
		"/offers/deals/[id]": [21],
		"/offers/events/[id]": [22],
		"/offers/places/[id]": [23],
		"/partner/dashboard": [24],
		"/partner/profile": [25],
		"/partner/socials": [26],
		"/partner/staff": [27],
		"/signup": [28],
		"/volunteer-opportunities": [31],
		"/volunteer-opportunities/[id]": [32],
		"/volunteer/dashboard": [29],
		"/volunteer/profile": [30]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';